<template>
  <div class="about">
    <sidebar-menu />

    <h1>This is an about page</h1>
  </div>
</template>
<script>
import SidebarMenu from "../components/SidebarMenu.vue";
export default {
  name: "About",
  components: { SidebarMenu },
};
</script>
